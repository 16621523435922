@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Manrope:300,300i,400,400i,500,500i,700,700i,900,900i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Base Styles */
* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

.invisible {
  display: none;
}

:root {
  --primary-color: #ff8811;
  --secondary-color: #f4d06f;
  --background-color: #fafafa;
  --grey-color: lightgrey;
  --dark-text-color: #333;
  --light-text-color: #fff;
  --border-color: #ddd;
  --gradient-primary: linear-gradient(
    45deg,
    var(--primary-color) 30%,
    var(--primary-color) 90%
  );
  --space-xs: 0.5rem;
  --space-sm: 1rem;
  --space-md: 1.5rem;
  --space-lg: 2.5rem;
  --space-xl: 4.5rem;
  --space-2xl: 6.5rem;
  --space-3xl: 8.5rem;
  --footer-height: 70px;
  --header-logo-height: 40px;
  --font-family: "Roboto", "sans-serif";
}

/* Utility Styles */
.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-right-1 {
  margin-right: 0.5rem;
  margin-right: var(--space-xs);
}

/* Container Styles */
.root {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.content {
  flex: 1 1;
  min-height: calc(100vh - 70px - 2.5rem);
  min-height: calc(100vh - var(--footer-height) - var(--space-lg));
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 70px;
  padding-top: var(--footer-height);
}

.componentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
  margin: var(--space-lg) 0;
  padding: 1.5rem;
  padding: var(--space-md);
  padding-top: 2.5rem;
  padding-top: var(--space-lg);
}

.pageProgessContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.language_btn {
  height: 45px;
  width: 45px;
  background-color: white;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: #f8f8fa;
    border: 1px solid #eaecf0;
  }

  &:focus {
    background-color: #eaecf0;
  }
}

.header-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.header-logo {
  max-height: 120px;
  height: 40px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: "pointer";
}

.language_dropdown-menu {
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin-top: 10px;

  .dropdown-item {
    text-decoration: none;
  }

  .active {
    background-color: #f8f8fa;
  }

  .language_dropdown-menu__item {
    padding: 15px;
    display: flex;
    flex-direction: row;

    &:hover {
      background-color: #f8f8fa;
    }

    .language_dropdown-menu__item__flag {
      height: 20px;
      width: 20px;
      margin-right: 18px;
    }
    .language_dropdown-menu__item__name {
      font-size: 20px;
      font-family: "Roboto", "sans-serif";
      font-family: var(--font-family);
      color: #333;
      color: var(--dark-text-color);
    }
  }
}

.language_icon {
  color: #232730;
  height: 16px;
  width: 16px;
}

/* Button Styles */
.button,
.main-button {
  background-color: #1976d2;
  color: var(--tertiary-color);
}

.button {
  padding: 1.5rem 2.5rem;
  padding: var(--space-md) var(--space-lg);
}

.main-button {
  padding: 1rem 1.5rem;
  padding: var(--space-sm) var(--space-md);
  margin-bottom: 1.5rem;
  margin-bottom: var(--space-md);
  display: block;
  margin: 1.5rem auto;
  margin: var(--space-md) auto;
}

/* Login Page Styles */
.loginPage {
  display: flex;
  height: 100vh;
  width: 100%;

  .loginImage {
    flex: 1 1;
    background-image: url(/static/media/login.f668a97c.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .loginFormWrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-top: var(--space-sm);
}

.loginFormBox {
  width: 100%;
  /* min-height: 60vh; */
  /* max-width: 80vh; */
  /* margin: var(--space-lg); */
  padding: 4.5rem;
  padding: var(--space-xl);
  padding-bottom: 8.5rem;
  padding-bottom: var(--space-3xl);
}

.loginLogoContainer {
  margin: 2.5rem;
  margin: var(--space-lg);
  margin-bottom: 1rem;
  margin-bottom: var(--space-sm);
  /* flex-wrap: wrap; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loginLogoContainer__line {
  margin-left: 40px;
  margin-right: 40px;

  height: 92px;
  border: 1px solid rgba(235, 232, 232, 1);
}

.loginLogo1 {
  max-height: 120px;
  height: auto;
}

.content.login-page-active {
  padding: 0;
  /* min-height: calc(60vh - var(--footer-height)); */
}

/* HomePage styles */
.homePage-title {
  margin-bottom: 2.5rem !important;
  margin-bottom: var(--space-lg) !important;
}

.text-disabled {
  color: rgba(0, 0, 0, 0.38);
}

/* Title Card styles */
/* Sorry for the "!important"s */
.titleCard-root {
  width: 420px;
  height: 420px;
  margin: 1rem;
  margin: var(--space-sm);
  background: linear-gradient(
    45deg,
    #ff8811 30%,
    #ff8811 90%
  );
  background: var(--gradient-primary);
  color: #fff;
  color: var(--light-text-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  /* transition: all 0.3s ease; */
  padding: 0;
}

.titleCard-disabled {
  color: lightgrey !important;
  cursor: not-allowed !important;
  background-size: cover !important;
  background-position: center !important;
}
.titleCard-actionArea {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
}

.titleCard-title {
  font-size: 32px !important;
  font-weight: bold !important;
  text-align: center !important;
}

/* Title Card styles with new backgrounds */
/* catalan */
.titleCard-style-lock-A-ca {
  background-image: url(/static/media/registre-pesada-verema-lock-ca.29575aff.png) !important;
}

.titleCard-style-lock-B-ca {
  background-image: url(/static/media/tramits-dun-lock-ca.ce07f730.png) !important;
}

.titleCard-style-unlock-A-ca {
  background-image: url(/static/media/registre-pesada-verema-unlock-ca.24546e26.png) !important;
}

.titleCard-style-unlock-B-ca {
  background-image: url(/static/media/tramits-dun-unlock-ca.ce07f730.png) !important;
}

/* spanish */
.titleCard-style-lock-A-es {
  background-image: url(/static/media/registre-pesada-verema-lock-es.4b16bc21.png) !important;
}

.titleCard-style-lock-B-es {
  background-image: url(/static/media/tramits-dun-lock-es.6439c984.png) !important;
}

.titleCard-style-unlock-A-es {
  background-image: url(/static/media/registre-pesada-verema-unlock-es.e900d8a4.png) !important;
}

.titleCard-style-unlock-B-es {
  background-image: url(/static/media/tramits-dun-unlock-es.6439c984.png) !important;
}

/* Lateral Menu styles */
.lateral-drawer {
  width: 250px;
  flex-shrink: 0;
  background-color: #fafafa;
  background-color: var(--background-color);
  overflow-x: hidden;
}

.lateral-logo {
  height: 40px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

/* Styling for the Menu Section */
.menu-section {
  background-color: #fafafa;
  background-color: var(--background-color);
  border-bottom: 1px solid #ddd;
  border-bottom: 1px solid var(--border-color);
  padding-left: 1rem;
  padding-left: var(--space-sm);
  padding-right: 1rem;
  padding-right: var(--space-sm);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.menu-section-icon {
  margin-right: 0.5rem;
  margin-right: var(--space-xs);
  display: inline-block;
  color: #ff8811;
  color: var(--primary-color);
  vertical-align: middle;
}

/* Styling for the Dropable Elements */
.dropable-element {
  padding-left: 2.5rem;
  padding-left: var(--space-lg);
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-gap: var(--space-xs);
  gap: var(--space-xs);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: lightgrey;
    background-color: var(--grey-color);
  }

  & > .dropable-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #ff8811;
    background-color: var(--primary-color);
  }
}

/* Form Styles */
.formBackground {
  background-image: url(/static/media/form-header.a7b3934b.png);
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.formComponentContainer {
  display: flex; /* Make the two containers inside componentContainer flex items */
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 8%;
  grid-gap: 30px;
  gap: 30px;
}

.descriptionContainer {
  display: flex; /* Make the two containers inside descriptionContainer flex items */
  flex-direction: column; /* Stack them vertically */
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.recordButtonContainer {
}

.recorderCard {
  display: flex;
  flex-direction: column;
  padding: 10px;
  grid-gap: 12px;
  gap: 12px;

  width: 600px;
  min-height: 240px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(235, 232, 232, 1);

  .recorderCard__title {
    height: 23px;

    font-family: "Roboto", "sans-serif";

    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.1px;
    text-align: left;
    color: rgba(63, 66, 84, 1);
  }

  .recorderCard__line {
    position: relative;
    left: -10px;

    width: calc(100% + 20px);
    border: 1px solid rgba(235, 232, 232, 1);
  }

  .recorderCard_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;

    padding-top: 25px;
    padding-bottom: 10px;

    .recorderCard__time {
      height: 35px;
      font-family: "Roboto", "sans-serif";
      font-family: var(--font-family);
      font-size: 50px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0.01em;
    }

    .recorderCard__state {
      min-height: 29px;
      margin-top: 5px;
      width: 245px;

      font-family: "Roboto", "sans-serif";

      font-family: var(--font-family);
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.01em;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }

    .recorderCard__button-hoverEffect:hover {
      background-color: rgba(231, 69, 58, 0.8);
      cursor: pointer;
    }

    .recorderCard__button {
      height: 58px;
      width: 58px;
      border-radius: 58px;
      background-color: rgba(231, 69, 58, 1);

      display: flex;
      justify-content: center;
      align-items: center;

      .recorderCard__button__mic {
        height: 24px;
        width: auto;
      }

      .recorderCard__button__pause {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 7px;
        gap: 7px;

        .recorderCard__button__pause_rec {
          height: 23px;
          width: auto;
        }
      }
    }

    .recorderCard__finish {
      margin-bottom: 10px;
      .recorderCard__finish__button {
        width: 94px;
        height: 48px;
        padding: 0px, 16px, 0px, 16px;
        border-radius: 6px;
        grid-gap: 8px;
        gap: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.cardTranscription-finished {
  background-color: rgba(251, 249, 249, 1) !important;
}

.cardTranscription {
  display: flex;
  flex-direction: column;
  padding: 10px;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 20px;

  width: 600px;
  height: 450px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(235, 232, 232, 1);

  .cardTranscription__title {
    margin-top: 5px;
    margin-left: 3px;

    font-family: "Roboto", "sans-serif";

    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: rgba(63, 66, 84, 1);
  }
  .cardTranscription__line {
    position: relative;
    left: -10px;

    width: calc(100% + 20px);
    border: 1px solid rgba(235, 232, 232, 1);
  }
  .cardTranscription-body__waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;

    height: 100%;
    .cardTranscription-body__waiting__text {
      width: 265px;
      height: auto;

      font-family: "Roboto", "sans-serif";

      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.01em;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .cardTranscription-body__text {
    padding: 5px;

    font-family: "Roboto", "sans-serif";

    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    color: rgba(63, 66, 84, 1);

    overflow-y: auto;
    max-height: 420px;
  }
}

.formContainer {
  width: calc(75% - 600px); /* 100% - width of topCardContainer - gap */
  margin-top: 7%;
}

.skeleton-container {
  margin-top: 20px;
  height: 50vh;
}

.formField {
  margin-bottom: 2.5rem;
  margin-bottom: var(--space-lg);
  padding: 0 2.5rem;
  padding: 0 var(--space-lg);
}

.formControl {
  min-width: 120px;
  width: 100%;
}

.formBottomButtonsContainer {
  margin: 1rem 0;
  margin: var(--space-sm) 0;
}

.submitButton,
.saveButton {
  margin: 1rem 0.5rem;
  margin: var(--space-sm) var(--space-xs);
}

.formHeader {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--space-lg);
  font-family: "Roboto", "sans-serif";
  font-family: var(--font-family);
}

.detailFormContainer {
  width: 100%;
  min-height: 60vh;
  margin: 2.5rem 0;
  margin: var(--space-lg) 0;
  padding: 2.5rem;
  padding: var(--space-lg);
}

@media (max-width: 960px) {
  .detailFormContainer {
    width: 90%;
  }
}

.formComponent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.beginButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--space-lg);
}

.beginButton {
  padding: 1rem 1.5rem;
  padding: var(--space-sm) var(--space-md);
}

.componentMargin {
  padding: 1rem 2.5rem;
  padding: var(--space-sm) var(--space-lg);
}

/* Footer */
.footer-container {
  width: 100%;
  height: 70px;
  height: var(--footer-height);
  padding: 1.5rem 0;
  padding: var(--space-md) 0;
  margin: 0;
  background-color: lightgrey;
  background-color: var(--grey-color);
  text-align: center;
}

.footer-text {
  color: #333;
  color: var(--dark-text-color);
}

